<template>
  <div class="relative h-screen flex justify-center items-center text-black scroll-snap-start">
    <navbar color="white" location="#aboutme" />
    <main class="text-center">
      <div class="text-right mr-5 md:mr-0">
        <p class="font-alternate text-4xl">"Everything should be made as simple as possible, but not simpler."</p>
        <p class="font-title font-bold text-gray-500 mt-5 tracking-wider">~ Albert Einstein</p>
      </div>
      <div class="absolute bottom-1/3 left-0 right-0 ml-auto mr-auto">
        <a class="underline" href="/CV_Driesen_Martijn.pdf" download="CV-Martijn-Driesen.pdf">Download mijn cv hier</a>
      </div>
    </main>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    return {};
  },
});
</script>
