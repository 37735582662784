<template>
  <div class="relative min-h-screen bg-black text-white scroll-snap-start flex justify-center items-center">
    <navbar location="#career" />
    <div class="w-screen">
      <div class="text-center pt-32">
        <v-h1>Loopbaan</v-h1>
        <div class="flex flex-col md:flex-row justify-between h-full w-full md:w-3/5 mx-auto mt-16">
          <div>
            <v-h1 class="pb-4">Opleiding</v-h1>
            <div v-for="education in educations" :key="education.title" class="grid grid-cols-3 gap-4">
              <div class="text-right col-span-2 pb-2">
                <p class="md:text-xl">{{ education.title }}</p>
                <p class="text-gray-700">{{ education.school }}</p>
              </div>
              <div class="flex items-center justify-center">{{ education.period }}</div>
            </div>
          </div>

          <div class="bg-gray-400 w-0.5"></div>
          <div class="pt-24 md:pt-0">
            <v-h1 class="pb-4">Jobs</v-h1>
            <div v-for="job in jobs" :key="job.title" class="grid grid-cols-3 gap-4 pb-2">
              <div class="flex items-center justify-center">{{ job.period }}</div>
              <div class="text-left col-span-2">
                <p class="md:text-xl">{{ job.title }}</p>
                <p class="text-gray-700">{{ job.location }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    const educations = [
      { title: "Applied Computer Science", school: "Thomas More Hogeschool - Geel", period: "Since 2017" },
      { title: "1th Year Teacher of Primary Education", school: "Hogeschool UCLL - Hasselt", period: "2016 - 2017" },
      { title: "Accounting and Computer Science", school: "Wico campus Sint-Jozef - Lommel", period: "2012 - 2017" },
    ];

    const jobs = [
      { title: "Freelance Services", location: "Martijn Driesen Freelance Services - Lommel", period: "Since 2020" },
      { title: "Waiter and Kitchen Aid", location: "Barbelicious - Eksel", period: "Since 2020" },
      { title: "Waiter and Bartender", location: "Face à Face - Bree", period: "2019 - 2021" },
      { title: "Waiter and Bartender", location: "Hamachi - Lommel", period: "2018" },
    ];
    return { educations, jobs };
  },
});
</script>
