
import { defineComponent } from "vue";
import AboutMe from "@/views/AboutMe.vue";
import Career from "@/views/Career.vue";
import Home from "@/views/Home.vue";
import Stage from "@/views/Stage.vue";
import Projects from "@/views/Projects.vue";

export default defineComponent({
  components: {
    Home,
    AboutMe,
    Career,
    Stage,
    Projects,
  },
});
