
import { defineComponent, ref } from "vue";

export default defineComponent({
  props: { color: { type: String, default: "Black" }, location: { type: String, required: true } },
  setup() {
    const links = ref({
      home: {
        text: "Home",
        link: "#home",
      },
      overMij: {
        text: "Over mij",
        link: "#aboutme",
      },
      career: {
        text: "Loopbaan",
        link: "#career",
      },
      stage: {
        text: "Stage",
        link: "#stage",
      },
      projects: {
        text: "Projecten",
        link: "#projects",
      },
    });
    return { links };
  },
});
