<template>
  <h1 class="font-title text-3xl"><slot /></h1>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "VH1",
});
</script>
