
import { defineComponent, ref } from "vue";

export default defineComponent({
  setup() {
    const images = ref({
      vuejs: require("@/assets/img/VueJs.png"),
      nestjs: require("@/assets/img/NestJs.svg"),
      strobbo: require("@/assets/img/strobbo.png"),
    });

    const evidence = ref({
      pva: {
        text: "Plan van aanpak",
        link: "/stage/pva.pdf",
        name: "Plan van aanpake - Martijn Driesen.pdf",
      },
      realisatie: {
        text: "Realisatie",
        link: "/stage/realisatie.pdf",
        name: "Realisatie - Martijn Driesen.pdf",
      },
      reflectie: {
        text: "Reflectie",
        link: "/stage/reflectie.pdf",
        name: "Reflectie - Martijn Driesen.pdf",
      },
    });

    return { images, evidence };
  },
});
