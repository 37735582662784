import { createApp } from "vue";
import "./assets/css/tailwind.css";
import App from "./App.vue";
import VueScrollTo from "vue-scrollto";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

// components
import H1 from "@/typography/h1.vue";
import Navbar from "@/components/Navbar.vue";

// font-awesome
library.add(faGithub);

createApp(App)
  // .use(router)
  .use(VueScrollTo)
  .component("VH1", H1)
  .component("navbar", Navbar)
  .component("fa", FontAwesomeIcon)
  .mount("#app");
