
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    const educations = [
      { title: "Applied Computer Science", school: "Thomas More Hogeschool - Geel", period: "Since 2017" },
      { title: "1th Year Teacher of Primary Education", school: "Hogeschool UCLL - Hasselt", period: "2016 - 2017" },
      { title: "Accounting and Computer Science", school: "Wico campus Sint-Jozef - Lommel", period: "2012 - 2017" },
    ];

    const jobs = [
      { title: "Freelance Services", location: "Martijn Driesen Freelance Services - Lommel", period: "Since 2020" },
      { title: "Waiter and Kitchen Aid", location: "Barbelicious - Eksel", period: "Since 2020" },
      { title: "Waiter and Bartender", location: "Face à Face - Bree", period: "2019 - 2021" },
      { title: "Waiter and Bartender", location: "Hamachi - Lommel", period: "2018" },
    ];
    return { educations, jobs };
  },
});
