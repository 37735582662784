<template>
  <div class="relative h-screen bg-black text-white scroll-snap-start flex flex-col justify-center items-center">
    <navbar location="#projects" />
    <v-h1 class="mb-8">Projects</v-h1>
    <p class="mb-24 max-w-md">
      Ik heb tijdens mijn opleiding verschillende projecten gemaakt. Hieronder vindt u een paar van deze projecten waar
      ik trots op ben. Deze projecten zijn allemaal te vinden op
      <a href="https://github.com/madriesen" target="_blank"><fa :icon="['fab', 'github']" />Github</a>
    </p>
    <div class="grid grid-cols-4 gap-10">
      <card v-for="project in projects" :key="project.link" :project="project" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import Card from "@/components/projects/Card.vue";

export default defineComponent({
  components: { Card },
  setup() {
    const projects = ref([
      {
        link: "madriesen/cashdesk",
        text: "Cashdesk",
        description:
          "Cashdesk is een programma gemaakt voor een cateraar die drive-in afhaal momenten organiseert. Het programma is geschreven met VueJs en NodeJs. Aangezien er vanuit gegaan moest worden dat hij niet overal internet verbinding zou hebben, is alles gehost op een Raspberry Pi, die zijn eigen wifi-netwerk uitzend. ",
      },
      {
        link: "Barbelicious",
        text: "Bestelsysteem Barbelicious",
        description:
          "Het bestelsysteem is een uitbreiding op madriesen/CashDesk, gemaakt met VueJs en NodeJs. Klanten van Barbelicious (campofuego.be) kunnen bestellen op het terras door een qr-code te scannen, en de bestellingen worden automatisch doorgeven naar de bar of de keuken. OPGELET: deze demo is gemaakt voor mobiele aparaten om zo snel te kunnen bestellen.",
        demo: "https://bestel.campofuego.be",
      },
      {
        link: "madriesen/ecommerce-contact",
        text: "E-commerce website",
        description:
          "Deze e-commerce website is gemaakt voor een klant om zo meer bereik te hebben. Een live demo kan je vinden via de github pagina.",
        demo: "https://ecommerce.martijndriesen.me",
      },
      {
        link: "madriesen/vue3-typescript-template",
        text: "Vue 3 Typescript template",
        description:
          "Dit is een template om snel te kunnen starten met projecten. Hierin zit de typescript integratie, als ook font-awesome en tailwindcss.",
      },
      {
        link: "madriesen/vue3-template",
        text: "Vue 3 starter template",
        description:
          "Dit is een template om snel te kunnen starten met projecten in vue 3 met font-awesome en tailwindcss.",
      },
      {
        link: "madriesen/vue-template",
        text: "Vue 2 starter template",
        description:
          "Dit is een template om snel te kunnen starten met projecten in vue 2 met font-awesome en tailwindcss",
      },
      {
        link: "madriesen/nodejs-express-typescript-template",
        text: "Nodejs Express Starter template",
        description:
          "Dit is een template om snel te kunnen starten met projecten in NodeJs Express met typescript en mongodb.",
      },
      {
        link: "madriesen/madriesen-express-helper",
        text: "NPM Express helper",
        description:
          "Om de responses en de logs van een NodeJs Express applicatie zo consistent mogelijk te maken heb ik een NPM package gemaakt. Deze voorziet de logging en de response handlers.",
      },
    ]);
    return { projects };
  },
});
</script>

<style scoped></style>
