
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: { project: { type: Object as PropType<Record<string, string>>, required: true } },
  emits: ["openGithub", "update:is-open", "openDemo"],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
  setup(props, { emit }) {
    const closeModal = () => {
      emit("update:is-open", false);
    };
    return { closeModal };
  },
});
