<template>
  <div class="h-screen scroll-snap-y">
    <home id="home" class="h-screen" />
    <about-me id="aboutme" class="h-screen" />
    <career id="career" class="h-screen" />
    <stage id="stage" class="h-screen" />
    <projects id="projects" class="h-screen" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import AboutMe from "@/views/AboutMe.vue";
import Career from "@/views/Career.vue";
import Home from "@/views/Home.vue";
import Stage from "@/views/Stage.vue";
import Projects from "@/views/Projects.vue";

export default defineComponent({
  components: {
    Home,
    AboutMe,
    Career,
    Stage,
    Projects,
  },
});
</script>
<style>
/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

* {
  scroll-behavior: smooth;
}
</style>
