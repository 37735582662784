<template>
  <div class="relative bg-white text-black scroll-snap-start flex flex-col justify-center items-center">
    <navbar color="white" location="#stage" />
    <v-h1 class="mb-16">Stage - Dynamic Settings Page</v-h1>
    <div class="grid lg:grid-cols-2 gap-10 max-w-5xl">
      <div>
        <p class="pb-3 text-justify">
          Ik kreeg de kans om stage te lopen bij
          <a href="https://strobbo.com" target="_blank" class="text-strobbo font-bold">Strobbo</a>, een snel groeiend
          bedrijf dat focust op het gemak van personeelsplanning en administratie.
          <a href="https://strobbo.com" target="_blank" class="text-strobbo font-bold">Strobbo</a> werkt met
          <a href="https://vuejs.org" target="_blank" class="text-strobbo font-bold">VueJs</a>, een front-end javascript
          framework, en <a href="https://nestjs.com" target="_blank" class="text-nest font-bold">NestJs</a>, gebouwd op
          het Express framwork van NodeJs.
        </p>
        <p class="text-justify">
          Mijn taak tijdens de stage was om, samen met 3 andere stagiairs, de interne instellingenpagina te vernieuwen.
          Deze had nood aan een dynamische opmaak waarbij de instellingen snel van plaats te kunnen veranderen.
        </p>
        <p class="text-justify">
          Tijdens mijn zoektocht naar een stageplaats had ik een aantal voorwaarden gesteld aan mezelf. De eerste was
          dat ik met nieuwe technologiën mocht werken, waar we op school nog niet mee gewerkt hadden. De tweede
          voorwaarde was dat het een KMO moest zijn aangezien ik niet in een te groot bedrijf wou werken. En de laatste
          voorwaarde was dat ik me tijdens het sollicitatiegesprek goed moest voelen bij de manier van werken. Strobbo
          was het bedrijf dat hierbij boven de rest uitstak. Ik ben zeer dankbaar dat ik bij Strobbo stage heb mogen
          lopen.
        </p>
      </div>
      <div class="text-right">
        <ul class="flex flex-col justify-around h-full">
          <li class="grid grid-cols-3 justify-around text-center">
            <a
              v-for="piece in evidence"
              :key="piece.text"
              class="underline"
              :href="piece.link"
              :download="piece.name"
              >{{ piece.text }}</a
            >
          </li>
          <li class="grid grid-cols-3 justify-around">
            <img src="@/assets/img/strobbo.png" alt="Strobbo" class="h-8 mr-auto ml-auto" /><img
              src="@/assets/img/VueJs.png"
              alt="Vuejs"
              class="h-16 mr-auto ml-auto"
            /><img src="@/assets/img/NestJs.svg" alt="Nestjs" class="h-16 mr-auto ml-auto" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  setup() {
    const images = ref({
      vuejs: require("@/assets/img/VueJs.png"),
      nestjs: require("@/assets/img/NestJs.svg"),
      strobbo: require("@/assets/img/strobbo.png"),
    });

    const evidence = ref({
      pva: {
        text: "Plan van aanpak",
        link: "/stage/pva.pdf",
        name: "Plan van aanpake - Martijn Driesen.pdf",
      },
      realisatie: {
        text: "Realisatie",
        link: "/stage/realisatie.pdf",
        name: "Realisatie - Martijn Driesen.pdf",
      },
      reflectie: {
        text: "Reflectie",
        link: "/stage/reflectie.pdf",
        name: "Reflectie - Martijn Driesen.pdf",
      },
    });

    return { images, evidence };
  },
});
</script>

<style scoped></style>
