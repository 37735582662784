<template>
  <div class="absolute top-0 right-8 w-1/3 mt-10" :class="color === 'white' ? ' text-black' : 'text-white'">
    <ul class="flex justify-between">
      <li v-for="link in links" :key="link.link">
        <a :class="location === link.link ? 'font-bold text-yellow-500' : ''" :href="link.link">{{ link.text }}</a>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  props: { color: { type: String, default: "Black" }, location: { type: String, required: true } },
  setup() {
    const links = ref({
      home: {
        text: "Home",
        link: "#home",
      },
      overMij: {
        text: "Over mij",
        link: "#aboutme",
      },
      career: {
        text: "Loopbaan",
        link: "#career",
      },
      stage: {
        text: "Stage",
        link: "#stage",
      },
      projects: {
        text: "Projecten",
        link: "#projects",
      },
    });
    return { links };
  },
});
</script>

<style scoped></style>
