<template>
  <div
    class="relative h-48 bg-white text-black flex w-72 flex-col justify-between text-center cursor-pointer group"
    @click="modalOpen = true"
  >
    <div class="h-36 w-full" :style="style"></div>
    <div class="pb-4 font-title group-hover:text-xl transition-card duration-700">
      {{ project.text }}
    </div>
  </div>
  <v-modal
    v-if="modalOpen"
    v-model:is-open="modalOpen"
    :project="project"
    @openGithub="openGithub"
    @openDemo="openDemo"
  />
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from "vue";
import VModal from "@/components/projects/Modal.vue";

export default defineComponent({
  name: "Card",
  components: { VModal },
  props: { project: { type: Object as PropType<Record<string, string>>, required: true } },
  setup(props) {
    const openGithub = () => {
      window.open(`https://www.github.com/${props.project.link}`, "_blank");
    };

    const openDemo = () => {
      if (props.project.demo) window.open(props.project.demo, "_blank");
    };

    const modalOpen = ref(false);

    const color = () => {
      return (
        "#" +
        Math.floor(Math.random() * 0xfffff * 1000000)
          .toString(16)
          .slice(0, 6)
      );
    };

    const createBackgroundString = (angle: number, start: string, end: string) => {
      return `linear-gradient(${angle}deg, ${start}, ${end})`;
    };

    const style = computed(() => {
      const startColor = color();
      const endColor = color();
      return { backgroundImage: createBackgroundString(45, startColor, endColor) };
    });

    return { modalOpen, openGithub, openDemo, style };
  },
});
</script>

<style scoped></style>
