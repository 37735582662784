<template>
  <header
    class="
      max-w-screen
      h-screen
      bg-black
      text-white
      flex flex-col
      md:flex-row
      items-center
      justify-around
      scroll-snap-start
      relative
    "
  >
    <navbar location="#home" />
    <div class="lg:w-3/5 xl:w-2/5 w-full text-center md:text-right">
      <div class="pt-24 md:pt-0 flex justify-center md:justify-end h-64 ml-auto">
        <div class="h-24 w-24 md:h-full md:mr-7 md:min-w-icon bg-logo">
          <!-- <img class="h-24 md:h-full" src="@/assets/img/icon_White.png" alt="" /> -->
        </div>
        <div class="hidden md:block w-1 h-24 md:h-full bg-white mr-5"></div>
        <div class="uppercase text-5xl md:text-title font-default tracking-widest leading-none">
          <p>martijn</p>
          <p>driesen</p>
        </div>
      </div>
      <v-h1 class="mt-5 md:mr-2 tracking-widest">Afstudeer portfolio</v-h1>
    </div>
    <div class="rounded-full font-title h-52 w-52 md:h-80 md:w-80 flex justify-center items-center bg-me">
      <!-- <div class="text-black">Dit ben ik</div> -->
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Home",
  setup() {
    return {};
  },
});
</script>

<style scoped>
.bg-me {
  background-image: url("~@/assets/img/me.jpg");
  background-position: center center;
  background-size: cover;
}

.bg-logo {
  background-image: url("~@/assets/img/icon_White.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}
</style>
